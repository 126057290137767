@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Roboto-Regular_0.woff');
    src: local('Roboto'),
        url('../fonts/Roboto-Regular_0.woff') format('woff'); 
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/Roboto-Italic_0.woff');
    src: local('Roboto'),
        url('../fonts/Roboto-Italic_0.woff') format('woff'); 
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Roboto-Light_0.woff');
    src: local('Roboto'),
        url('../fonts/Roboto-Light_0.woff') format('woff'); 
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/Roboto-LightItalic_0.woff');
    src: local('Roboto'),
        url('../fonts/Roboto-LightItalic_0.woff') format('woff'); 
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/Roboto-Thin_0.woff');
    src: local('Roboto'),
        url('../fonts/Roboto-Thin_0.woff') format('woff'); 
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100;
    src: url('../fonts/Roboto-ThinItalic_0.woff');
    src: local('Roboto'),
        url('../fonts/Roboto-ThinItalic_0.woff') format('woff'); 
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Roboto-Medium_0.woff');
    src: local('Roboto'),
        url('../fonts/Roboto-Medium_0.woff') format('woff'); 
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/Roboto-MediumItalic_0.woff');
    src: local('Roboto'),
        url('../fonts/Roboto-MediumItalic_0.woff') format('woff'); 
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Roboto-Bold_0.woff');
    src: local('Roboto'),
        url('../fonts/Roboto-Bold_0.woff') format('woff'); 
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/Roboto-BoldItalic_0.woff');
    src: local('Roboto'),
        url('../fonts/Roboto-BoldItalic_0.woff') format('woff'); 
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/Roboto-Black_0.woff');
    src: local('Roboto'),
        url('../fonts/Roboto-Black_0.woff') format('woff'); 
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    src: url('../fonts/Roboto-BlackItalic_0.woff');
    src: local('Roboto'),
        url('../fonts/Roboto-BlackItalic_0.woff') format('woff'); 
    font-display: swap;
}