@media screen and (max-width: 1023px){
    :root{
        --main-indent: 5%;
    }

    header{
        height: 50px;
    }

    header .left .logo a{
        font-size: 20px;
    }

    header .left .logo a img{
        width: 35vw;
        max-width: 170px;
    }

    header .right .menuBtn{
        display: block;
        width: fit-content;
        margin-left: auto;    
    }

    header .right .menuBtn .line{
        background-image: url('../img/btn_line.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 31px;
        height: 4px;
        transition: transform 0.25s;
    }

    header .right .menuBtn .line:nth-child(1){
        margin-bottom: 4px;
    }

    header .right .menuBtn.active .line:nth-child(1), header .right .menuBtn.active_for_search .line:nth-child(1){
        transform: rotateZ(42deg) translateY(2px);
        margin-bottom: 0;
    }

    header .right .menuBtn.active .line:nth-child(2), header .right .menuBtn.active_for_search .line:nth-child(2){
        transform: rotateZ(-42deg) translateY(-2px);
        margin-bottom: 0;
    }

    header .right nav, main#blog .search form {
        text-align: center;
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: 10vh;
        z-index: 10;
        visibility: hidden;
        background-color: var(--main-bg-color);
        opacity: 0;
        transition: opacity 0.5s;
    }

    header .right .menuBtn.active ~ nav {
        visibility: visible;
        opacity: 1;
    }

    header .right nav ul li {
        display: block;
        font-size: 22px;
        margin-left: 0;
        margin-bottom: 32px;
    }

    header .right nav ul li.phone_number {
        font-size: 24px;
        font-weight: 700;
        margin-left: 0;
        position: absolute;
        width: 100%;
        bottom: 7vh;
    }

    footer {
        padding: 68px var(--main-indent);
    }

    footer .top{
        flex-wrap: wrap;
    }

    footer .top .start {
        width: 100%;
        margin-bottom: 44px;
    }

    footer .top .start p {
        width: 64%;
        margin-top: 30px;
        margin-bottom: 0;
    }

    footer .top .start .logo a img{
        width: 35vw;
        max-width: 170px;
    }

    footer .top .debthunch {
        margin: 0 28% 0 0;
    }

    footer .top h4{
        margin-bottom: 25px;
    }

    footer .top ul li{
        margin-bottom: 16px;
    }

    footer .top .contact {
        margin-left: 0;
        margin-top: 44px;
        width: 100%;
    }

    footer .top .contact ul li {
        text-align: left;
    }

    footer .bottom {
        margin-top: 57px;
    }

    footer .bottom p {
        text-align: left;
    }


    main {
        margin-top: 50px;
    }

    main h3{
        font-size: 24px;
        line-height: 36px;
    }

    main h1 {
        font-size: 36px;
        line-height: 48px;
    }

    main#index .start img, main#services .top .right{
        display: none;
    }

    main#index h1 {
        font-size: 36px;
        line-height: 48px;
        width: 98%;
        margin: auto;
    }

    main#index .start .top {
        width: 100%;
    }

    main#index .start .top>p, main#services .top .left p {
        font-size: 18px;
        line-height: 30px;
        margin: 25px 0 0 0;
    }

    main button.standart {
        font-size: 17px;
        padding: 22px 82px;
    }

    main#index .start .top button.standart {
        margin: 35px auto 10px;
    }

    main#index .start .top form p, main#services .top .left form p {
        font-size: 16px;
        margin-top: 33px;
        margin-bottom: 7px;
    }

    main#index .start .top form p span, main#services .top .left form p span{
        font-size: 16px;
    }

    main#index .start .top form input, main#services .top .left form input {
        width: 100%;
        max-width: unset;
    }

    main#index .start .bottom {
        margin-top: 27px;
        flex-wrap: wrap;
    }

    main#index .start .bottom ul{
        width: 100%;
    }

    main#index .start .bottom ul li {
        font-size: 18px;
        margin-right: 0;
        width: 45%;
        box-sizing: border-box;
        margin-bottom: 15px;
    }

    main#index .start .bottom ul li:nth-child(odd) {
        text-align: right;
    }

    main#index .start .bottom ul li:nth-child(1){
        padding-right: 4%;
    }

    main#index .start .bottom ul li:nth-child(2){
        padding-left: 4%;
    }

    main#index .start .bottom ul li:nth-child(4){
        padding-left: 9%;
    }

    main#index .start, main#services .top {
        padding-top: 80px;
        padding-bottom: 20px;
        border-radius: 0 0 245px 0;
    }

    main#index .start .bottom p, main#services .top>p.warning {
        margin-left: 0;
        margin-top: 45px;
        font-size: 11px;
        width: 60%;
        line-height: 16px;
    }

    main#index .guide {
        padding: 65px 0 43px;
        flex-wrap: wrap;
    }

    main#index .guide h3 {
        font-size: 24px;
        width: 100%;
        margin: 0 0 20px;
    }

    main#index .guide>div{
        width: 100%;
        padding: 0;
        margin-right: 0;
        margin-bottom: 22px;
    }

    main#index .guide h4 {
        font-size: 22px;
        margin: 0 0 25px;
    }

    main#index .guide>div .icon {
        width: 105px;
        height: 105px;
        left: -18px;
    }

    main#index .guide>div:hover {
        background-color: transparent;
        box-shadow: none;
    }

    main#index .guide p {
        font-size: 16px;
        line-height: 24px;
        width: 98%;
    }

    main#index .consolidation {
        margin-top: 57px;
        padding: 0;
    }

    main#index .consolidation h3 {
        margin: 0;
        width: 89%;
        text-align: left;
        padding: 0 var(--main-indent);
    }

    main#index .consolidation .items .arrow{
        display: none;
    }

    main#index .consolidation .items .container {
        width: 100%;
        padding: 45px 0;
        overflow-x: scroll;
    }

    main#index .consolidation .items .container::-webkit-scrollbar {
        width: 0;
    }

    main#index .consolidation .items .item {
        width: 90vw;
        margin-right: 4vw;
        box-shadow: 2px 7px 23px 2px #f3f3f3;
    }

    main#index .consolidation .items .item .line .name {
        font-size: 36px;
        width: 70px;
        height: 70px;
        left: 2px;
    }

    main#index .consolidation .items .item .line {
        width: 35px;
    }

    main#index .consolidation .items .item .info {
        width: calc(100% - 35px);
        box-sizing: border-box;
        padding: 37px 8% 27px 0;
    }

    main#index .consolidation .items .item .info>.name {
        font-size: 18px;
        margin-left: 58px;
    }

    main#index .consolidation .items .item .info .date {
        font-size: 14px;
        margin: 15px 0 30px 58px;
    }

    main#index .consolidation .items .item .info p {
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 46px 28px;
    }

    main#index .consolidation .items .item .info .origin{
        margin-left: 28px;
    }

    main#index .whyUse .top .text {
        width: 100%;
        margin-top: 60px;
        margin-left: 0;
    }

    main#index .whyUse .top{
        flex-wrap: wrap;
    }

    main#index .whyUse .top .img {
        width: 100%;
        max-width: unset;
        order: 2;
        position: relative;
        left: -5%;
    }

    main#index .whyUse .top .img img {
        width: 110%;
    }

    main#index .whyUse {
        border-radius: 0 245px 0 0;
        padding: 0 var(--main-indent) 60px;
    }

    main#index .whyUse .top .text span {
        font-size: 22px;
        line-height: 30px;
        width: 90%;
    }

    main#index .whyUse .top .text p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
        margin-bottom: 25px;
    }

    main#index .whyUse .types {
        flex-wrap: wrap;
        margin-top: 60px;
        margin-left: 0;
    }

    main#index .whyUse .types h3 {
        font-size: 24px;
        line-height: 36px;
        width: 77%;
    }

    main#index .whyUse .types>div {
        width: 100%;
        margin-left: 0;
        margin-top: 37px;
    }

    main#index .whyUse .types>div.notaccepted{
        margin-top: 60px;
    }

    main#index .whyUse .types>div h4 {
        font-size: 22px;
        margin: 0 0 30px;
    }

    main#index .whyUse .types>div ul li {
        font-size: 16px;
    }

    main#index .options{
        margin-top: 60px;
        padding: 0;
    }

    main#index .options h3 {
        margin: 0;
        text-align: left;
        width: 90%;
        padding: 0 var(--main-indent);
    }

    main#index .options .container {
        width: 100%;
        margin: 45px auto 0;
    }

    main#index .options .container .row{
        flex-wrap: wrap;
        border-bottom: 1px solid #ebebeb;
        padding-bottom: 45px;
        margin-bottom: 45px;
    }

    main#index .options .container .row .img{
        order: 1;
    }

    main#index .options .container .row a{
        order: 2;
        width: 100%;
        padding: 0 var(--main-indent);
        margin-top: 45px;
    }

    main#index .options .container .row:nth-child(1) .img, 
    main#index .options .container .row:nth-child(2) .img,
    main#index .options .container .row:nth-child(3) .img{
        margin: auto;
    }

    main#index .options .container .row .text h4 {
        font-size: 22px;
        margin: 0 0 20px;
    }

    main#index .options .container .row .text h4:hover{
        text-decoration: underline;
    }

    main#index .options .container .row .text p {
        font-size: 16px;
        line-height: 24px;
    }

    main#index .options .container .row:nth-child(2) a{
        margin-left: 0;
    }

    main#index .options .container .row:nth-last-child(1){
        border: none;
    }

    main#index .alert, main#services .alert, main#single_blog .subscribe {
        padding: 60px 12% 60px;
        position: relative;
        overflow: hidden;
    }

    main#index .alert .text button, main#services .alert .text button, main#single_blog .subscribe form button {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    main#index .alert .text h3, main#services .alert .text h3, main#single_blog .subscribe form h3 {
        margin: 0 0 40px;
        font-weight: 700;
    }

    main#index .alert .coin, main#services .alert .coin, main#single_blog .subscribe .coin{
        position: absolute;
        top: -35px;
        left: -60px;
        z-index: 0;
    }

    main#index .alert .coin.right, main#services .alert .coin.right, main#single_blog .subscribe .coin.right{
        top: unset;
        left: unset;
        right: -50px;
        bottom: -45px;
    }

    main#index .alert .text, main#services .alert .text, main#single_blog .subscribe form{
        width: 100%;
        position: relative;
        z-index: 1;
    }

    main#index .options .container .row a:hover{
        background-color: transparent;
        box-shadow: none;
    }

    main#contact_us .top {
        flex-wrap: wrap;
        padding-top: 80px;
    }

    main#contact_us .top form {
        width: 100%;
        margin-right: 0;
        margin-top: 0;
    }

    main#contact_us .top .img {
        width: 100%;
        position: relative;
        right: unset;
        left: -5%;
        margin-top: 50px;
    }

    main#contact_us .top .img img {
        width: 110%;
        max-width: unset;
    }

    main#contact_us .top form h2 {
        margin-bottom: 45px;
        font-size: 36px;
    }

    main form.standart label {
        margin-bottom: 20px;
    }

    main#contact_us button.standart{
        padding-left: 50px;
        padding-right: 50px;
    }

    main#contact_us .contact {
        margin-top: 60px;
        padding: 0 0 50px;
    }

    main#contact_us .contact h1{
        padding: 0 var(--main-indent);
        margin-bottom: 20px;
    }

    main#contact_us .contact p {
        font-size: 18px;
        margin-bottom: 40px;
        padding: 0 var(--main-indent);
    }

    main#contact_us .contact ul{
        flex-wrap: wrap;
        padding: 0 var(--main-indent);
    }

    main#contact_us .contact ul li{
        width: 100%;
        margin-bottom: 30px;
    }

    main#contact_us .contact ul li:nth-child(2), main#contact_us .contact ul li:nth-child(1){
        margin-right: 0;
    }

    main#contact_us .contact ul li .title{
        margin-bottom: 6px;
        font-size: 18px;
    }

    main#contact_us .contact ul li .text {
        font-size: 18px;
        line-height: 30px;
    }

    main#contact_us .contact iframe{
        margin-top: 20px;
        height: 300px;
    }

    main#about_us .top {
        padding-top: 75px;
        padding-bottom: 110px;
        border-radius: 0 0 245px 0;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    main#about_us .top .text {
        width: 100%;
    }

    main#about_us .top .text p {
        margin-top: 20px;
        font-size: 18px;
        line-height: 30px;
    }

    main#about_us .top .img {
        width: 100%;
        max-width: unset;
        margin-left: 0;
        margin-top: 65px;
        position: relative;
        left: -5%;
    }

    main#about_us .top .img img {
        width: 110%;
    }

    main#about_us .debthunch {
        padding-top: 60px;
        padding-bottom: 40px;
        flex-wrap: wrap;
    }

    main#about_us .debthunch h3 {
        width: 100%;
    }

    main#about_us .debthunch .text {
        width: 100%;
        margin-left: 0;
        margin-top: 35px;
    }

    main#about_us .debthunch .text p, 
    main#about_us .working .text p, 
    main#about_us .whyUse .text ul li p,
    main#licensing>div p,
    main#privacy_policy>div .text p,
    main#services .second ul li .text p,
    main#services .third ul li .text p,
    main#single_blog>.text p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 25px;
    }

    main#about_us .clip {
        padding: 0 0 60px;
        flex-wrap: wrap;
    }

    main#about_us .clip .video {
        width: 100%;
        max-width: unset;
        order: 2;
    }

    main#about_us .clip h3 {
        width: 100%;
        margin-bottom: 40px;
        padding: 0 var(--main-indent);
    }

    main#about_us .working {
        flex-wrap: wrap;
    }

    main#about_us .working h3 {
        width: 100%;
    }

    main#about_us .working .text {
        width: 100%;
        margin-left: 0;
        margin-top: 35px;
    }

    main#about_us .working .text .title {
        font-size: 22px;
        line-height: 34px;
        margin-top: 40px;
    }

    main#about_us .working .text ul li {
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
    }

    main#about_us .whyUse {
        flex-wrap: wrap;
        margin-top: 35px;
    }

    main#about_us .whyUse h3 {
        margin: 0 0 60px;
        width: 100%;
        order: 1;
    }

    main#about_us .whyUse .text {
        width: 100%;
        margin-top: 0;
        order: 2;
    }

    main#about_us .whyUse .text ul li .title {
        font-size: 22px;
        line-height: 34px;
    }

    main#about_us .whyUse .text ul li {
        margin-bottom: 40px;
    }

    main#about_us .whyUse .text ul li:nth-last-child(1), main#about_us .whyUse .text ul li:nth-last-child(1) p {
        margin-bottom: 0;
    }

    main#licensing>div, main#privacy_policy>div {
        padding-top: 70px;
        padding-bottom: 15px;
    }

    main#licensing>div h1, main#privacy_policy>div h1 {
        margin-bottom: 35px;
    }

    main#privacy_policy>div .text{
        width: 100%;
    }

    main#privacy_policy>div .text h4 {
        font-size: 18px;
        margin: 35px 0 15px;
    }

    main#privacy_policy>div .text ul li {
        margin-bottom: 20px;
    }

    main#privacy_policy>div{
        padding-bottom: 25px;
    }

    main#services .top{
        padding-left: var(--main-indent);
        padding-right: var(--main-indent);
    }

    main#services .top .left{
        width: 100%;
    }

    main#services .top .left .group{
        display: block;
        margin: 0;
    }

    main#services .top>p.warning{
        position: static;
        margin-top: 120px;
    }

    main#services .top .left button{
        margin: 35px 0 10px;
        padding-left: 35px;
        padding-right: 35px;
    }

    main#services .top .left .group span{
        margin-left: 0;
    }

    main#services .top .left form p{
        margin-bottom: 15px;
        margin-top: 55px;
    }

    main#services .second {
        padding-top: 60px;
    }

    main#services .second ul li{
        flex-wrap: wrap;
        margin-bottom: 35px;
    }

    main#services .second ul li h3, main#services .third h3 {
        width: 100%;
    }

    main#services .second ul li .text {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        order: 2;
    }

    main#services .third{
        flex-wrap: wrap;
        margin-top: 40px;
        margin-bottom: 35px;
    }

    main#services .third ul {
        margin-left: 0;
        width: 100%;
        margin-top: 25px;
    }

    main#services .third ul li{
        width: 100%;
        margin-bottom: 10px;
    }

    main#services .third ul li .icon {
        width: 100px;
        height: 100px;
    }

    main#services .third ul li h4{
        font-size: 22px;
    }

    main#blog .top {
        padding-top: 70px;
        display: block;
        padding-bottom: 110px;
        border-radius: 0 0 245px
    }

    main#blog .top .text{
        width: 100%;
    }

    main#blog .top .text p {
        margin: 20px 0 0;
        font-size: 18px;
        line-height: 30px;
    }

    main#blog .top .img {
        width: 117%;
        max-width: unset;
        margin-left: 0;
        right: unset;
        left: -12%;
        margin-top: 10px;
        overflow: hidden;
    }

    main#blog .top .img img {
        width: 130%;
    }

    main#blog .search form{
        width: 100%;
    }

    main#blog .search .share{
        width: 100%;
    }

    main#blog .search .share span {
        font-size: 16px;
        margin-left: 0;
    }

    main#blog .search {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    main#blog .types ul{
        flex-wrap: wrap;
        padding: 25px 0 5px;
    }

    main#blog .types ul li {
        font-size: 16px;
        margin-bottom: 20px;
    }

    main#blog .items {
        margin-top: 50px;
    }

    main#blog .items .item {
        width: 100%;
        margin-bottom: 45px;
        margin-right: 0;
    }

    main#blog .items .item:nth-last-child(1) {
        margin-bottom: 0;
    }

    main#blog .items .item .text .title {
        font-size: 22px;
        line-height: 34px;
    }

    main#blog .items .item .text, main#blog .items .item .text .intro_text {
        margin-top: 15px;
    }

    main#blog .items .item .text .intro_text {
        font-size: 16px;
        max-height: 96px;
        line-height: 24px;
    }

    main#blog .pages {
        margin-bottom: 0px;
        margin-top: 40px;
    }

    main#blog .pages ul{
        display: none;
    }

    main#blog .pages .seemore{
        display: block;
        text-transform: uppercase;
        width: fit-content;
        margin: auto;
        border: 1px solid var(--main-color);
        border-radius: 3px;
        font-size: 14px;
        padding: 15px 40px;
    }

    main#blog .search .search_icon{
        background-image: url('../img/search_icon_2.png');
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        position: fixed;
        top: 16px;
        right: calc(var(--main-indent) + 60px);
        width: 18px;
        height: 18px;
        z-index: 2;
    }
    
    main#blog .search .search_icon.active ~ form {
        visibility: visible;
        opacity: 1;
    }

    main#blog .search .search_icon.active{
        background: none;
        top: 10px;
        right: calc(var(--main-indent));
        width: 35px;
        height: 30px;
    }

    main#blog .search form label::before{
        display: none;
    }

    main#blog .search form label input {
        width: calc(100% - 2*var(--main-indent));
        border-bottom: 1px solid #919191;
        font-size: 22px;
        background: none;
        margin: auto;
        padding-bottom: 10px;
    }

    main#single_blog>div{
        padding: 0 var(--main-indent);
    }

    main#single_blog>.img {
        padding-top: 50px;
    }

    main#single_blog>.date {
        font-size: 14px;
        margin-top: 15px;
    }

    main#single_blog>.title h1 {
        font-size: 24px;
        line-height: 36px;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    main#single_blog .intro_text {
        font-size: 18px;
        line-height: 28px;
    }

    main#single_blog .share {
        margin-top: 30px;
    }

    main#single_blog>.text {
        margin-top: 40px;
    }

    main#single_blog>.text h3, main#single_blog>.text h1, main#single_blog>.text h2 {
        font-size: 18px;
        margin: 0 0 15px;
    }

    main#single_blog .types ul li {
        font-size: 12px;
    }

    main#single_blog .types {
        margin-top: 45px;
    }

    main#single_blog .types ul a {
        padding: 12px 10px;
        margin-right: 10px;
        margin-bottom: 20px;
    }

    main#single_blog .suggestions {
        padding: 45px var(--main-indent) 5px;
        border-radius: 0;
        margin-top: 50px;
    }

    main#single_blog .suggestions h2 {
        margin-bottom: 40px;
        font-size: 24px;
    }

    main#single_blog .suggestions .items .item, main#single_blog .suggestions .items .item:nth-last-child(-n + 4) {
        width: 100%;
        margin-bottom: 40px;
        display: none;
        margin-right: 0;
    }

    main#single_blog .suggestions .items .item:nth-child(-n + 3) {
        display: block;
    }

    main#single_blog .suggestions .items .item a .img {
        width: 30%;
        height: 75px;
    }

    main#single_blog .suggestions .items .item a .text .title {
        font-size: 18px;
        line-height: 24px;
        max-height: 96px;
    }

    main#single_blog .suggestions .items .item a .text {
        width: 60%;
    }

    main#single_blog .suggestions .items .item a .text .date {
        font-size: 14px;
        margin-top: 20px;
    }

    main#single_blog .subscribe form input{
        margin-right: 0;
        border-radius: 3px 0 0 3px;
    }

    main#single_blog .subscribe form button{
        border-radius: 0 3px 3px 0;
    }

    main#offers .data {
        flex-wrap: wrap;
        padding-top: 75px;
        padding-bottom: 45px;
    }

    main#offers .data .left {
        width: 100%;
    }

    main#offers .data .left h2 {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 40px;
    }

    main#offers .data .left .img{
        display: none;
    }

    main#offers .data .right {
        width: 100%;
        margin-left: 0;
    }

    main#offers .data .right>div label{
        width: 100%;
        font-size: 16px;
    }

    main#offers .data .right>div.debt label:nth-child(2) input{
        width: 100%;
    }

    main#offers .data .right>div.personal {
        margin-top: 20px;
    }

    main#offers .data .right>div label.short{
        width: 100%;
    }

    main#offers .data .right>div label.short input, main#offers .data .right>div label.short .select{
        width: 61%;
    }

    main#offers .data .right>div label.short.alon{
        margin-left: 0;
    }

    main#offers .data .right>div label input{
        font-size: 16px;
    }

    main#offers .data .right>div button {
        padding-left: 40px;
        padding-right: 40px;
        height: auto;
        margin-top: 0;
    }

    main#offers .data .right>div>p {
        font-size: 12px;
        line-height: 16px;
        width: 100%;
        margin-top: 15px;
    }

    main#offers .results {
        padding-top: 75px;
    }

    main#offers .results .greeding {
        font-size: 22px;
        line-height: 34px;
    }

    main#offers .results>p {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        margin-top: 20px;
    }

    main#offers .results .container {
        margin-top: 50px;
    }

    main#offers .results .container .row{
        margin-bottom: 45px;
    }

    main#offers .results .container .row:nth-last-child(1){
        margin-bottom: 0;
    }

    main#offers .results .container .row .info {
        font-size: 18px;
        line-height: 30px;
        padding: 15px 20px;
    }

    main#offers .results .container .row .tr {
        flex-wrap: wrap;
        padding: 50px 20px 30px;    
        border-bottom: 1px solid #ebebeb;
    }

    main#offers .results .container .row .tr:nth-child(1) {
        padding-top: 30px;
    }

    main#offers .results .container .row .tr:nth-last-child(1) {
        border-bottom: none;
    }

    main#offers .results .container .row .tr .td,
    main#offers .results .container .row .tr .td.logo,
    main#offers .results .container .row .tr .td:nth-child(2), 
    main#offers .results .container .row .tr .td:nth-child(3), 
    main#offers .results .container .row .tr .td:nth-last-child(2), 
    main#offers .results .container .row .tr .td:nth-last-child(3){
        width: 100%;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 30px;
    }

    main#offers .results .container .row .tr .td .value {
        font-size: 24px;
        margin-bottom: 10px;
        width: 100%;
    }

    main#offers .results .container .row .tr .td .desc {
        font-size: 16px;
        line-height: 24px;
    }

    main#offers .results .container .row .tr .td.logo img {
        max-width: 45%;
    }

    main#offers .results .container .row .tr .td button {
        padding: 18px 0;
        margin-left: 0;
        width: 60%;
    }

    main#offers .msg>div {
        width: calc(100% - 2*var(--main-indent));
        height: 28vh;
        border-radius: 4px;
        padding: 15px 13px;
    }

    main#offers .msg>div .title {
        font-size: 18px;
        margin-bottom: 7px;
    }

    main#offers .msg>div .text {
        font-size: 17px;
    }

    main#offers .msg>div button.standart{
        margin: auto;
    }
}

@media screen and (min-width: 565px) and (max-width: 1023px){
    header .right nav ul li{
        margin-bottom: 18px;
    }

    header .right nav{
        padding-top: 6vh;
    }
}