:root{
    --main-hover-color: #396ee5;
    --main-indent: 10.5%;
    --main-bg-color: #f4f6fb;
    --main-color: #232323;
}

*{
    font-family: 'Roboto';
    color: var(--main-color);
}

a, div {
    -webkit-tap-highlight-color: transparent;
}

body{
    margin: 0;
}

ul, ol{
    margin: 0;
    padding: 0;
}

a{
    text-decoration: none;
}

input, textarea, button, a{
    outline: none;
}

input, textarea{
    border-radius: 0;
    padding: 0;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

header{
    position: fixed;
    width: 100%;
    height: 88px;
    z-index: 2;
    top: 0;
    display: flex;
    align-items: center;
    background-color: var(--main-bg-color);
    padding: 0 var(--main-indent);
    box-sizing: border-box;
    border: 1px solid #ebebeb;
}

header .left{
    width: 30%;
}

header .left .logo a{
    color: var(--main-hover-color);
    font-size: 24px;
    font-weight: 700;
}

header .left .logo a img{
    display: block;
    width: 14vw;
    max-width: 200px;
}

header .right{
    width: 70%;
}

header .right .menuBtn{
    display: none;
}

header .right nav{
    text-align: right;
}

header .right nav ul li{
    list-style-type: none;
    display: inline-block;
    font-size: 15px;
    margin-left: 4.5vw;
}

header .right nav ul li:nth-child(1){
    margin-left: 0;
}

header .right nav ul li.phone_number{
    font-size: 18px;
    font-weight: 700;
    margin-left: 5.5vw;
}

header .right nav ul li a{
    text-decoration: none;
}

header .right nav ul li a:hover{
    color: var(--main-hover-color);
}



footer{
    padding: 88px var(--main-indent);
}

footer .top{
    width: 100%;
    display: flex;
    align-items: flex-start;
}

footer .top .start{
    width: 20%;
}

footer .top .start .logo a{
    font-size: 24px;
    font-weight: 700;
    color: var(--main-hover-color);
}

footer .top .start .logo a img{
    display: block;
    width: 11.6vw;
    max-width: 180px;
}

footer .top .start p{
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    margin-top: 25px;
    color: #585858;
}

footer .top h4{
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 33px;
    margin-top: 0;
    color: #585858;
}

footer .top ul li{
    list-style-type: none;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 12px;
}

footer .top ul li a{
    text-decoration: none;
    color: #585858;
}

footer .top ul li a:hover{
    color: var(--main-hover-color);
}

footer .top .contact{
    margin-left: auto;
}

footer .top .contact ul li{
    text-align: right;
}

footer .top .contact ul li a{
    text-decoration: underline;
    color: var(--main-hover-color);
}

footer .top .contact ul li a:hover{
    text-decoration: none;
}

footer .top .debthunch{
    margin: 0 8% 0 12%;
}

footer .bottom{
    margin-top: 76px;
}

footer .bottom p{
    font-size: 14px;
    font-weight: 300;
    text-align: right;
    margin: 0;
    color: #585858;
}


main{
    margin-top: 88px;
}

main>div{
    width: 100%;
    padding: 0 var(--main-indent);
    box-sizing: border-box;
}

main h1{
    font-size: 59px;
    font-weight: 900;
    line-height: 70px;
    margin: 0;
}


/*--------------------- index ----------------------*/
main#index .start{
    background-color: var(--main-bg-color);
    padding-top: 110px;
    padding-bottom: 15px;
    border-radius: 0 0 175px 0;
    position: relative;
}

main#index .start .top{
    width: 47%;
    text-align: center;
    margin: auto;
}

main#index .start .top>p{
    font-size: 23px;
    line-height: 34px;
    margin: 22px 0 0 0;
}

main#index .start .top button.standart{
    margin: 27px auto 9px;
}

main button.standart{
    cursor: pointer;
    color: white;
    background-color: #22c197;
    border: none;
    text-transform: uppercase;
    display: block;
    font-size: 16px;
    font-weight: 700;
    padding: 17px 76px;
    border-radius: 3px;
    transition: all 0.1s;
}

main button.standart:hover{
    background-color: #da3051;
}

main button.standart.red{
    background-color: #da3051;
}

main button.standart.red:hover{
    background-color: #22c197;
}

main .start .top span{
    font-size: 13px;
    font-weight: 300;
    color: #a2a3a6;
}

main#index .start .top form p{
    font-size: 17px;
    margin-top: 25px;
    margin-bottom: 12px;
}

main#index h1{
    font-size: 55px;
}

main#index .start .top form p span{
    font-size: 17px;
    font-weight: 700;
    color: var(--main-hover-color);
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.1s;
}

main#index .start .top form p span:hover{
    color: #22c197;
}

main#index .start .top form input{
    border: 1px solid #ebebeb;
    font-size: 16px;
    border-radius: 3px;
    width: 70.8%;
    max-width: 360px;
    box-sizing: border-box;
    padding: 16px 18px;
    visibility: hidden;
}

main#index .start .top form input::placeholder{
    color: #a9a9a9;
}

main#index .start img{
    position: absolute;
}

main#index .start img.person{
    width: 19%;
    max-width: 257px;
    top: 18.7%;
}

main#index .start img.arrow{
    right: 5%;
    width: 23%;
    max-width: 310px;
    top: 35.3%;
}

main#index .start .bottom{
    width: 100%;
    display: flex;
    align-items: flex-end;
    margin-top: 109px;
}

main#index .start .bottom ul li{
    list-style-type: none;
    display: inline-block;
    font-size: 21px;
    margin-right: 3.5vw;
}

main#index .start .bottom ul li:nth-last-child(1){
    margin-right: 0;
}

main#index .start .bottom ul li span{
    color: var(--main-hover-color);
}

main#index .start .bottom p{
    margin: 0;
    margin-left: auto;
    font-size: 12px;
    font-weight: 100;
}

main#index .guide{
    display: flex;
    padding: 130px 0 70px;
    border-bottom: 1px solid #ebebeb;
    margin: 0 var(--main-indent);
    width: auto;
}

main h3{
    font-size: 39px;
    font-weight: 300;
    line-height: 51px;
}

main#index .guide h3{
    font-size: 34px;
    width: 25%;
    margin: 25px 0 0 0;
}

main#index .guide h4{
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 11px;
}

main#index .guide p{
    font-size: 12px;
    line-height: 20px;
    margin: 0;
    width: 89%;
}

main#index .guide>div{
    width: 24%;
    padding: 1.5% 2.5% 4%;
    box-sizing: border-box;
    margin-right: 1.4%;
    cursor: pointer;
    transition: all 0.25s;
}

main#index .guide>div:hover{
    background-color: var(--main-bg-color);
    box-shadow: 7px 4px 42px 4px #f3f3f3;
}

main#index .guide>div:nth-last-child(1){
    margin-right: 0;
}

main#index .guide>div .icon{
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    left: -13px;
}

main#index .guide>div:nth-child(2) .icon{
    background-image: url('../img/time.png');
}

main#index .guide>div:nth-child(3) .icon{
    background-image: url('../img/cooperation.png');
}

main#index .guide>div:nth-child(4) .icon{
    background-image: url('../img/solution.png');
}

main#index .consolidation{
    margin-top: calc(70px + 3%);
}

main#index .consolidation h3{
    margin: 0 auto;
    width: 58%;
    text-align: center;
}

main#index .consolidation .items{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

main#index .consolidation .items .arrow{
    background-image: url('../img/arr_right.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 33px;
    height: 33px;
    cursor: pointer;
}

main#index .consolidation .items .arrow.left{
    transform: rotateZ(180deg);
    margin-right: 21;
    visibility: hidden;
}

main#index .consolidation .items .arrow.right{
    margin-left: 1%;
}

main#index .consolidation .items .container{
    width: 90%;
    padding: 70px 4%;
    box-sizing: border-box;
    overflow: hidden;
}

main#index .consolidation .items .container>div{
    display: flex;
    width: fit-content;
    transition: all 0.5s;
}

main#index .consolidation .items .item{
    width: 29.5vw;
    margin-right: 5vw;
    box-shadow: 2px 7px 36px 2px #f3f3f3;
    display: flex;
}

main#index .consolidation .items .item .line{
    background-color: var(--main-bg-color);
    width: 18%;
    height: 100%;
    position: relative;
}

main#index .consolidation .items .item .line .name{
    background-size: cover;
    background-position: center;
    color: #fff;
    font-size: 29px;
    font-weight: 300;
    background-color: #2196f3;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: calc(100% - 28px);
    top: 25px;
}

main#index .consolidation .items .item .info{
    width: 82%;
    box-sizing: border-box;
    padding: 9.5% 10% 8%;
}

main#index .consolidation .items .item .info .name{
    font-size: 17px;
    font-weight: 700;
}

main#index .consolidation .items .item .info .date{
    font-size: 13px;
    color: #757575;
    margin: 13px 0 16px;
}

main#index .consolidation .items .item .info p{
    font-size: 14px;
    line-height: 21px;
    margin: 0 0 46px;
}

main#index .consolidation .items .item .info .origin{
    display: flex;
    align-items: center;
}

main#index .consolidation .items .item .info .origin span{
    font-size: 13px;
    color: #757575;
}

main#index .consolidation .items .item .info .origin span:nth-child(4){
    margin-left: 1px;
}

main#index .consolidation .items .item .info .origin .name{
    font-size: 13px;
    color: #757575;
    font-weight: 400;
    display: inline-block;
}

main#index .consolidation .items .item .info .origin .logo{
    width: 13px;
    margin-right: 9px;
    margin-left: 1px;
}

main#index .consolidation .items .item .info .origin .stars{
    display: inline-flex;
    margin-left: 11px;
}

main#index .consolidation .items .item .info .origin .stars .star{
    background-image: url('../img/star.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    margin-right: 1px;
}

main#index .whyUse{
    background-color: var(--main-bg-color);
    border-radius: 175px 0 0;
    margin-top: 6.5%;
    padding-bottom: 80px;
    padding-left: 8.2%;
    position: relative;
}

main#index .whyUse .top{
    display: flex;
}

main#index .whyUse .top .text{
    width: 36.8vw;
    margin-top: 80px;
    margin-left: auto;
}

main#index .whyUse .top .text h3{
    margin-bottom: 40px;
    margin-top: 0;
    text-transform: uppercase;
}

main#index .whyUse .top .text span{
    font-size: 23px;
    line-height: 30px;
    color: var(--main-hover-color);
    font-weight: 400;
    width: 85%;
    display: block;
}

main#index .whyUse .top .text p{
    font-size: 15px;
    line-height: 22px;
    margin-top: 17px;
    margin-bottom: 20px;
}

main#index .whyUse .top .img{
    width: 43vw;
    max-width: 590px;
}

main#index .whyUse .top .img img{
    width: 100%;
    display: block;
}

main#index .whyUse .types{
    display: flex;
    margin-top: 180px;
    margin-left: 3%;
}

main#index .whyUse .types h3{
    font-size: 37px;
    text-transform: uppercase;
    width: 38%;
    margin: 0;
}

main#index .whyUse .types>div{
    width: 25%;
    margin-left: 5%;
    margin-top: 6px;
}

main#index .whyUse .types>div h4{
    font-size: 23px;
    font-weight: 400;
    margin: 0 0 31px;
}

main#index .whyUse .types>div ul li{
    font-size: 15px;
    list-style-type: none;
    margin-top: 18px;
}

main#index .whyUse .types>div ul li::before{
    content: "";
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
}
    
main#index .whyUse .types>div.accepted ul li::before{
    background-image: url('../img/v.png');
    width: 16px;
    height: 12px;
    margin-right: 24px;
}

main#index .whyUse .types>div.notaccepted ul li::before{
    background-image: url('../img/x.png');
    width: 11px;
    height: 12px;
    margin-right: 30px;
}

main#index .options{
    margin-top: 80px;
}

main#index .options h3{
    margin: 0 auto 0;
    text-align: center;
    width: 69%;
    text-transform: uppercase;
}

main#index .options .container{
    width: calc(83% + 60px);
    margin: 100px auto 0;
}

main#index .options .container .row{
    display: flex;
    align-items: center;
    margin-bottom: 115px;
}

main#index .options .container .row a{
    width: 44%;
    padding: 30px;
    transition: all 0.25s;
}

main#index .options .container .row a .text{
    width: 100%;
}

main#index .options .container .row a:hover{
    background-color: var(--main-bg-color);
    box-shadow: 7px 4px 42px 4px #f3f3f3;
}

main#index .options .container .row:nth-child(2) a{
    margin-left: 26%;
}

main#index .options .container .row .text h4{
    color: var(--main-hover-color);
    font-weight: 400;
    font-size: 23px;
    margin: 0 0 16px;
}

main#index .options .container .row .text p{
    font-size: 15px;
    line-height: 22px;
    margin: 0;
}

main#index .options .container .row:nth-child(1) .img{    
    margin-left: 26.5%;
    margin-top: 15px;
}

main#index .options .container .row:nth-child(2) .img{    
    margin-left: 9.5%;
}

main#index .options .container .row:nth-child(3) .img{    
    margin-left: 23.5%;;
}

main#index .alert, main#services .alert, main#single_blog .subscribe{
    background-color: var(--main-hover-color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 9.2% 60px;
}

main#index .alert .text, main#services .alert .text, main#single_blog .subscribe form{
    width: 63%;
    margin: auto;
    text-align: center;
}

main#index .alert .text h3, main#services .alert .text h3, main#single_blog .subscribe form h3{
    color: white;
    margin: 0 0 50px;
}

main#index .alert .text button, main#services .alert .text button, main#single_blog .subscribe form button{
    margin: auto;
}

main#index .alert .coin, main#services .alert .coin, main#single_blog .subscribe .coin{
    background-image: url('../img/coins2.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 190px;
    height: 172px;
}

main#index .alert .coin.right, main#services .alert .coin.right, main#single_blog .subscribe .coin.right{
    background-image: url('../img/coins3.png');
}




/*------------------------- contact us -------------------------*/
main#contact_us{
    background-color: var(--main-bg-color);
}

main#contact_us .top{
    display: flex;
    padding-top: 30px;
}

main#contact_us .top form{
    width: 35%;
    margin-right: 5%;
    margin-top: 90px;
}

main form.standart label{
    width: 100%;
    display: block;
    margin-bottom: 15px;
}

main form.standart label.right input, main form.standart label.right textarea, main#single_blog .subscribe form label.right input{
    border-color: #2dce89;
}

main form.standart label.wrong input, main form.standart label.wrong textarea, main#single_blog .subscribe form label.wrong input{
    border-color: red;
}

main form.standart input{
    display: block;
    border: 1px solid #ebebeb;
    font-size: 15px;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 16px 18px;
    width: 100%;
}

main form.standart textarea{
    display: block;
    border: 1px solid #ebebeb;
    font-size: 15px;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 16px 18px;
    width: 100%;
    resize: none;
}

main form.standart input::placeholder, main form.standart textarea::placeholder{
    color: #a9a9a9;
}

main#contact_us .top form h2{
    margin: 0px 0 90px;
    font-size: 59px;
    font-weight: 900;
}

main#contact_us .top .img{
    width: 60%;
    position: relative;
    right: -53px;
}

main#contact_us .top .img img{
    width: 100%;
    display: block;
    max-width: 700px;
    margin-left: auto;
}

main#contact_us .contact{
    margin-top: 125px;
    padding-bottom: 90px;
}

main#contact_us .contact h1{
    margin: 0px 0 17px;
}

main#contact_us .contact p{
    font-size: 23px;
    margin: 0px 0 55px;
    text-transform: uppercase;
}

main#contact_us .contact ul{
    display: flex;
}

main#contact_us .contact ul li {
    list-style-type: none;
    width: 22%;
}

main#contact_us .contact ul li:nth-child(1) {
    margin-right: 2%;
}

main#contact_us .contact ul li:nth-child(2) {
    margin-right: 10%;
}

main#contact_us .contact ul li .title {
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--main-hover-color);
    margin-bottom: 4px;
}

main#contact_us .contact ul li .text {
    font-size: 17px;
    line-height: 25px;
}

main#contact_us .contact ul li a{
    text-decoration: underline;
}

main#contact_us .contact ul li a:hover{
    text-decoration: none;
}

main#contact_us .contact iframe{
    width: 100%;
    margin-top: 55px;
    height: 500px;
}




/*------------------------- about us -------------------------*/
main#about_us .top{
    background-color: var(--main-bg-color);
    padding-top: 70px;
    padding-bottom: 90px;
    border-radius: 0 0 175px 0;
    display: flex;
    align-items: center;
}

main#about_us h3{
    text-transform: uppercase;
}

main#about_us .top .text{
    width: 43%;
}

main#about_us .top .text p{
    margin: 18px 0 0;
    font-size: 23px;
    line-height: 34px;
}

main#about_us .top .img{
    width: 55%;
    max-width: 600px;
    margin-left: auto;
}

main#about_us .top .img img{
    width: 100%;
}

main#about_us .debthunch{
    padding-top: 145px;
    padding-bottom: 145px;
    display: flex;
}

main#about_us .debthunch h3{
    margin: 0;
    width: 33%;
}

main#about_us .debthunch .text{
    width: 60%;
    margin-left: auto;
    margin-top: 7px;
}

main#about_us .debthunch .text p{
    font-size: 15px;
    line-height: 22px;
    margin: 0 0 20px;
}

main#about_us .clip{
    padding-bottom: 145px;
    display: flex;
}

main#about_us .clip h3{
    width: 33%;
    margin: 0 0 0 auto;
}

main#about_us .clip .video{
    width: 56%;
    max-width: 610px;
}

main#about_us .clip .video video{
    width: 100%;
    display: block;
}

main#about_us .clip .video video:focus{
    outline: none;
}

main#about_us .working{
    display: flex;
}

main#about_us .working h3{
    margin: 0;
    width: 33%;
}

main#about_us .working .text{
    width: 60%;
    margin-left: auto;
    margin-top: 7px;
}

main#about_us .working .text p{
    font-size: 15px;
    line-height: 22px;
    margin: 0 0 20px;
}

main#about_us .working .text p a{
    color: var(--main-hover-color);
    text-decoration: none;
}

main#about_us .working .text .title{
    font-size: 17px;
    margin-top: 35px;
    color: var(--main-hover-color);
}

main#about_us .working .text ul{
    margin-bottom: 33px;
}

main#about_us .working .text ul li{
    font-size: 15px;
    line-height: 22px;
    list-style-type: none;
    margin-top: 18px;
}

main#about_us .working .text ul li::before {
    content: "";
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../img/v.png');
    display: inline-block;
    width: 16px;
    height: 12px;
    margin-right: 24px;
}

main#about_us .whyUse{
    display: flex;
    margin-top: 125px;
}

main#about_us .whyUse .text{
    width: 57%;
    margin-top: 7px;
}

main#about_us .whyUse .text ul li{
    list-style-type: none;
    margin-bottom: 90px;
}

main#about_us .whyUse .text ul li .title{
    color: var(--main-hover-color);
    font-size: 23px;
    font-weight: 700;
    margin-bottom: 20px;
}

main#about_us .whyUse .text ul li p{
    font-size: 15px;
    line-height: 22px;
    margin: 0;
}

main#about_us .whyUse h3{
    margin: 0 0 0 auto;
    width: 33%;
}




/*------------------------- licensing -------------------------*/
main#licensing>div{
    padding-top: 208px;
    padding-bottom: 40px;
    background-color: var(--main-bg-color);
}

main#licensing>div p{
    font-size: 17px;
    margin: 0 0 30px;
}

main#licensing>div h1{
    margin-bottom: 70px;
}




/*------------------------- privacy policy -------------------------*/
main#privacy_policy>div{
    padding-top: 208px;
    padding-bottom: 50px;
    background-color: var(--main-bg-color);
}

main#privacy_policy>div h1{
    margin-bottom: 70px;
}

main#privacy_policy>div .text{
    width: 75%;
}

main#privacy_policy>div .text h4{
    font-weight: 700;
    font-size: 20px;
    margin: 50px 0 20px;
}

main#privacy_policy>div .text p{
    font-size: 17px;
    line-height: 27px;
    margin: 0 0 30px;
}

main#privacy_policy>div .text p:nth-last-child(1){
    max-width: 260px;
}

main#privacy_policy>div .text p a{
    color: var(--main-hover-color);
}

main#privacy_policy>div .text ul li{
    list-style-type: none;
    margin-bottom: 13px;
}

main#privacy_policy>div .text ul li::before{
    content: "\2022";
    color: var(--main-hover-color);
    font-weight: bold;
    display: inline-block; 
    margin-right: 10px;
}




/*------------------------- services -------------------------*/
main#services .top{
    background-color: var(--main-bg-color);
    padding-top: 40px;
    padding-bottom: 80px;
    padding-right: 0;
    border-radius: 0 0 175px 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

main#services .top .right{
    width: 58%;
    max-width: 770px;
    margin-left: auto;
    position: relative;
    right: 2%;
}

main#services .top .right img{
    width: 100%;
    display: block;
}

main#services .top .left{
    width: 40.5%;
}

main#services .top .left p{
    font-size: 23px;
    line-height: 34px;
    margin: 22px 0 0 0;
}

main#services .top .left .group{
    margin: 27px 0 9px;
    display: flex;
    align-items: center;
}

main#services .top .left .group span{
    font-size: 13px;
    font-weight: 300;
    color: #a2a3a6;
    margin-left: 15px;
}

main#services .top .left form p{
    font-size: 17px;
    margin-top: 30px;
    margin-bottom: 5px;
}

main#services .top .left form p span{
    font-size: 17px;
    font-weight: 700;
    color: var(--main-hover-color);
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.1s;
}

main#services .top .left form p span:hover{
    color: #22c197;
}

main#services .top .left form input{
    border: 1px solid #ebebeb;
    font-size: 16px;
    border-radius: 3px;
    width: 70.8%;
    max-width: 360px;
    box-sizing: border-box;
    padding: 16px 18px;
    visibility: hidden;
}

main#services .top .left form input::placeholder {
    color: #a9a9a9;
}

main#services .top>p.warning{
    margin: 0;
    font-size: 12px;
    font-weight: 100;
    position: absolute;
    bottom: 2%;
}

main#services .second{
    padding-top: 145px;
}

main#services .second ul li{
    list-style-type: none;
    display: flex;
    margin-bottom: 125px;
} 

main#services .second ul li h3{
    margin: 0;
    width: 33%;
    text-transform: uppercase;
}

main#services .second ul li .text{
    width: 60%;
    margin-left: auto;
    margin-top: 7px;
}

main#services .second ul li:nth-child(2) .text{
    margin-left: 0;
    margin-right: auto;
}

main#services .second ul li .text p {
    font-size: 15px;
    line-height: 22px;
    margin: 0 0 20px;
}

main#services .third{
    display: flex;
    margin-bottom: 90px;
}

main#services .third h3{
    margin: 0;
    width: 34%;
    text-transform: uppercase;
}

main#services .third ul{
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    width: 60%;
}

main#services .third ul li{
    list-style-type: none;
    width: 45%;
    margin-bottom: 55px;
}

main#services .third ul li:nth-child(even){
    margin-left: auto;
}

main#services .third ul li h4{
    margin: 0 0 20px;
    font-size: 23px;
    line-height: 34px;
    font-weight: 400;
}

main#services .third ul li .text p{
    font-size: 15px;
    line-height: 22px;
    margin: 0 0 20px;
}

main#services .third ul li .icon{
    width: 93px;
    height: 93px;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    left: -16px;
    top: -2px;
}

main#services .third ul li:nth-child(1) .icon{
    background-image: url('../img/icon_1.png');
}

main#services .third ul li:nth-child(2) .icon{
    background-image: url('../img/cooperation.png');
}

main#services .third ul li:nth-child(3) .icon{
    background-image: url('../img/solution.png');
}

main#services .third ul li:nth-child(4) .icon{
    background-image: url('../img/icon_2.png');
}




/*------------------------- blog -------------------------*/
main#blog .top{
    background-color: var(--main-bg-color);
    padding-top: 20px;
    padding-bottom: 80px;
    border-radius: 0 0 175px 0;
    display: flex;
    align-items: center;
}

main#blog .top .text{
    width: 37%;
}

main#blog .top .text p {
    margin: 18px 0 0;
    font-size: 23px;
    line-height: 34px;
}

main#blog .top .img{
    width: 63%;
    max-width: 680px;
    margin-left: auto;
    position: relative;
    right: -4%;
}

main#blog .top .img img{
    width: 100%;
}

main#blog .search{
    display: flex;
    align-items: center;
    padding-top: 22px;
    padding-bottom: 22px;
}

main#blog .search .search_icon{
    display: none;
}

main#blog .search form{
    width: 60%;
}

main#blog .search form label{
    width: 100%;
    display: flex;
    align-items: center;
}

main#blog .search form label::before{
    content: "";
    background-image: url('../img/search_icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 15px;
}

main#blog .search form label input{
    width: calc(100% - 45px);
    border: none;
    font-size: 15px;
    border-radius: 0;
}

main#blog .search form label input::placeholder{
    color: #bcbcbc;
}

main#blog .search .share{
    width: 30%;
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: right;
}

main#blog .search .share span{
    color: #bcbcbc;
    font-size: 15px;
    margin-left: auto;
}

main#blog .search .share ul{
    display: inline-block;
}

main#blog .search .share ul button, main#single_blog .share ul button, main#blog .search .share ul a, main#single_blog .share ul a{
    margin-left: 28px;
}

main#blog .search .share ul .in, main#single_blog .share ul .in{
    margin-left: 36px;
}

main#blog .search .share ul  li, main#single_blog .share ul  li{
    list-style-type: none;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    height: 17px;
}

main#blog .search .share ul .fb li, main#single_blog .share ul .fb li{
    background-image: url('../img/fb_icon.png');
    width: 8px;
}

main#blog .search .share ul .in li, main#single_blog .share ul .in li{
    background-image: url('../img/in_icon.png');
    width: 17px;
}

main#blog .search .share ul .tw li, main#single_blog .share ul .tw li{
    background-image: url('../img/tw_icon.png');
    width: 21px;
}

main#blog .search .share ul .fb:hover li, main#single_blog .share ul .fb:hover li{
    background-image: url('../img/fb_icon_hover.png');
}

main#blog .search .share ul .in:hover li, main#single_blog .share ul .in:hover li{
    background-image: url('../img/in_icon_hover.png');
}

main#blog .search .share ul .tw:hover li, main#single_blog .share ul .tw:hover li{
    background-image: url('../img/tw_icon_hover.png');
}

main#blog .types ul{
    display: flex;
    justify-content: space-between;
    border: 1px solid #ebebeb;
    border-left: none;
    border-right: none;
    padding: 22px 0;
}

main#blog .types ul li{
    list-style-type: none;
    font-size: 13px;
    cursor: pointer;
    /* transition: color 0.25s; */
}

main#blog .types ul li.active{
    color: var(--main-hover-color);
}

main#blog .items{
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
}

main#blog .items .item{
    width: 30%;
    margin-bottom: 85px;
    margin-right: 5%;
}

main#blog .items .item:nth-child(3n + 3){
    margin-right: 0;
}

main#blog .items .item:nth-last-child(-n + 3){
    margin-bottom: 65px;
}

main#blog .items .item .img, main#blog .items .item .img img{
    width: 100%;
    display: block;
}

main#blog .items .item .text{
    margin-top: 13px;
}

main#blog .items .item .text .title{
    font-size: 23px;
    line-height: 32px;
    font-weight: 700;
    color: var(--main-hover-color);
}

main#blog .items .item .text .intro_text{
    font-size: 15px;
    line-height: 22px;
    max-height: 88px;
    overflow: hidden;
    color: #7e7e7e;
    transition: color 0.2s;
    margin-top: 13px;
}

main#blog .items .item .text .intro_text:hover{
    color: var(--main-color);
}

main#blog .items .item .text .date{
    font-weight: 300;
    font-size: 13px;
    color: #7e7e7e;
    margin-top: 22px;
}

main#blog .pages{
    margin-bottom: 80px;
}

main#blog .pages .seemore{
    display: none;
}

main#blog .pages ul{
    width: fit-content;
    margin-left: auto;
}

main#blog .pages ul li{
    list-style-type: none;
    display: inline-block;
    margin-left: 30px;
    font-size: 14px;
    color: #a9a9a9;
    cursor: pointer;
}

main#blog .pages ul li:hover{
    color: var(--main-color);
}

main#blog .pages ul li.active{
    color: var(--main-hover-color);
}



/*------------------------- single_blog -------------------------*/
main#single_blog>div{
    padding: 0 calc(var(--main-indent)*2.1);
}

main#single_blog>.img{
    width: 100%;
    padding-top: 40px;
}

main#single_blog>.img img{
    width: 100%;
    display: block;
}

main#single_blog>.date{
    font-size: 13px;
    font-weight: 300;
    color: #7e7e7e;
    margin-top: 22px;
}

main#single_blog>.title h1{
    font-size: 39px;
    line-height: 54px;
    width: 80%;
    margin-top: 25px;
    margin-bottom: 19px;
}

main#single_blog .intro_text{
    color: #7e7e7e;
    font-size: 23px;
    line-height: 34px;
}

main#single_blog .share{
    margin-top: 35px;
}

main#single_blog .share ul button:nth-child(1){
    margin-left: 0;
}

main#single_blog>.text{
    margin-top: 45px;
}

main#single_blog>.text p{
    margin: 0 0 30px;
    font-size: 17px;
    line-height: 28px;
}

main#single_blog>.text h3, main#single_blog>.text h1, main#single_blog>.text h2{
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 11px;
    line-height: inherit;
}

main#single_blog>.text p a{
    color: var(--main-hover-color);
}

main#single_blog .types{
    margin-top: 60px;
}

main#single_blog .types ul{
    display: flex;
    flex-wrap: wrap;
}

main#single_blog .types ul a{
    background-color: #f2f2f2;
    padding: 12px 10px;
    margin-right: 18px;
    margin-bottom: 18px;
    border-radius: 3px;
    transition: all 0.2s;
}

main#single_blog .types ul a:hover{
    background-color: #e9e9e9;
}

main#single_blog .types ul li{
    list-style-type: none;
    font-size: 11px;
    color: #787878;
}

main#single_blog .suggestions{
    padding: 60px var(--main-indent) 80px;
    background-color: #f4f6fb;
    border-radius: 175px 0 0;
    margin-top: 72px;
}

main#single_blog .suggestions h2{
    margin: 0 0 80px;
    font-size: 23px;
    font-weight: 700;
}

main#single_blog .suggestions .items{
    display: flex;
    flex-wrap: wrap;
}

main#single_blog .suggestions .items .item{
    width: 22%;
    margin-bottom: 70px;
    margin-right: 4%;
}

main#single_blog .suggestions .items .item:nth-child(4n + 4){
    margin-right: 0;
}

main#single_blog .suggestions .items .item:nth-last-child(-n + 4){
    margin-bottom: 0;
}

main#single_blog .suggestions .items .item a{
    display: flex;
}

main#single_blog .suggestions .items .item a .img{
    width: 45%;
    height: 78px;
    position: relative;
    margin-left: auto;
    overflow: hidden;
}

main#single_blog .suggestions .items .item a .img img{
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    display: block;
}

main#single_blog .suggestions .items .item a .text{
    width: 48%;
}

main#single_blog .suggestions .items .item a .text .title{
    font-size: 17px;
    font-weight: 700;
    color: var(--main-hover-color);
    line-height: 22px;
    max-height: 88px;
    overflow: hidden;
}

main#single_blog .suggestions .items .item a .text .title:hover{
    text-decoration: underline;
}

main#single_blog .suggestions .items .item a .text .date{
    font-weight: 300;
    font-size: 13px;
    color: #7e7e7e;
    margin-top: 16px;
}

main#single_blog .subscribe form h3{
    font-weight: 700;
}

main#single_blog .subscribe form>label{
    height: 47px;
    display: flex;
}

main#single_blog .subscribe form input{
    border: 1px solid #ebebeb;
    font-size: 13px;
    border-radius: 3px;
    width: 360px;
    margin-right: 20px;
    margin-left: auto;
    box-sizing: border-box;
    padding: 16px 18px;
    height: 100%;
    box-sizing: border-box;
}

main#single_blog .subscribe form label.wrong input::placeholder{
    color: red;
}

main#single_blog .subscribe form label.right input::placeholder{
    color: #2dce89;
}

main#single_blog .subscribe form input::placeholder {
    color: #a9a9a9;
}

main#single_blog .subscribe form button{
    font-size: 16px;
    font-weight: 700;
    background-color: #ff8a62;
    text-transform: uppercase;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    height: 100%;
    box-sizing: border-box;
    margin-left: 0;
    padding-left: 40px;
    padding-right: 40px;
}




/*------------------------- offers -------------------------*/
main#offers .data{
    background-color: var(--main-bg-color);
    display: flex;
    padding-top: 145px;
    padding-bottom: 80px;
}

main#offers .data .left{
    width: 45%;
}

main#offers .data .left h2{
    font-size: 37px;
    font-weight: 300;
    line-height: 54px;
    text-transform: uppercase;
    margin: 0 0 75px;
}

main#offers .data .left .img{
    width: 95%;
    max-width: 480px;
    position: relative;
    left: -3%;
}

main#offers .data .left .img img{
    width: 100%;
    display: block;
}

main#offers .data .right{
    width: 50%;
    margin-left: auto;
}

main#offers .data .right h3{
    color: var(--main-hover-color);
    font-weight: 400;
    font-size: 23px;
    margin: 0 0 20px;
    width: 100%;
}

main#offers .data .right>div{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

main#offers .data .right>div label{
    width: 47%;
    font-size: 15px;
    margin-bottom: 25px;
}

main#offers .data .right>div label.short{
    width: 20.5%;
}

main#offers .data .right>div label.short.alon{
    margin-right: auto;
    margin-left: 6%;
}

main#offers .data .right>div label input{
    display: block;
    margin-top: 6px;
    border: 1px solid #ebebeb;
    font-size: 14px;
    border-radius: 3px;
    color: #a9a9a9;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 18px;
}

main#offers .data .right>div label.wrong input{
    border-color: red;
}

main#offers .data .right>div label input:focus{
    color: var(--main-color);
}

main#offers .data .right>div label input::placeholder{
    color: #a9a9a9;
}

main#offers .msg{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

main#offers .msg>div{
    background: white;
    width: 30vw;
    height: 28vh;
    box-shadow: 3px 8px 35px -7px #dcdcdc;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 4px;
    padding: 1.5vh 1vw;
    box-sizing: border-box;
}

main#offers .msg>div .title{
    text-align: center;
    width: 100%;
    color: #396ee5;
    font-size: 17px;
    margin-top: 10px;
}

main#offers .msg>div.error .title{
    color: #fb0b0b;
}

main#offers .msg>div .text{
    width: 100%;
    text-align: center;
    font-size: 16px;
    align-self: self-start;
}

main#offers .msg>div button.standart {
    font-size: 15px;
    padding: 10px 50px;
    margin-left: auto;
    align-self: flex-end;
}




.select{
  display: block;
  height: 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  color: #a9a9a9;
  margin-top: 6px;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}

.select:hover .css-1uccc91-singleValue{
    color: var(--main-color);
}

.select .css-1wa3eu0-placeholder, .select .css-1uccc91-singleValue{
    color: #a9a9a9;
}

.css-1okebmr-indicatorSeparator{
  display: none;
}

.css-tlfecz-indicatorContainer{
    padding: 16px 18px;
}

main .css-9gakcf-option{
  background-color: #f0f0f0;
  color: var(--main-color);
  padding: 6px 9px 6px 16px;
  font-weight: 400;
}

main .css-1n7v3ny-option{
  background-color: #f0f0f0;
  color: var(--main-color);
  padding: 6px 9px 6px 16px;
  font-weight: 400;
  cursor: pointer;
}

main .css-yt9ioa-option{
  padding: 6px 9px 6px 16px;
  font-weight: 400;
}

main .css-9gakcf-option:active, main .css-1n7v3ny-option:active{
  background-color: transparent;
  color: var(--main-color);
}

main .css-9gakcf-option:focus, main .css-1n7v3ny-option:focus{
    outline: none;
}

main .css-yt9ioa-option:active{
  background-color: #eeeeee;
}

main .css-1pahdxg-control:hover, main .css-1pahdxg-control{
    border: 1px solid #ebebeb;
    border-radius: 3px;
}

main .css-1pahdxg-control{
  box-shadow: none;
  height: 50px;
}

main .css-26l3qy-menu{
  margin-top: 0;
  padding: 0;
  z-index: 5;
  border-radius: 3px;
  border: 1px solid #ebebeb;
  box-shadow: none;
}

main .css-1sxd95b-MenuList, main .css-56iqpq{
  padding: 0;
}

.css-1sxd95b-MenuList::-webkit-scrollbar, .css-56iqpq::-webkit-scrollbar {
  width: 10px;
}

.css-1sxd95b-MenuList::-webkit-scrollbar-track, .css-56iqpq::-webkit-scrollbar-track {
  background: #f6f6f5;
  border: 1px solid #ebebeb;
  border-radius: 3px;
}

main .css-tlfecz-indicatorContainer, main .css-1gtu0rj-indicatorContainer{
  padding: 20px;
  background-image: url('../img/triangle.png');
  background-repeat: no-repeat;
  background-position: center;
}

main .css-g1d714-ValueContainer{
    padding: 0 16px;
    height: 48px;
}

main .css-6q0nyr-Svg, main .css-19bqh2r{
  display: none;
}

.css-1sxd95b-MenuList::-webkit-scrollbar-thumb, .css-56iqpq::-webkit-scrollbar-thumb {
  background: #a4a2a2;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  box-sizing: border-box;
}

main .css-yk16xz-control:hover{
    border-color: #ebebeb;
}

/* .css-1sxd95b-MenuList::-webkit-scrollbar-thumb:hover, .css-56iqpq::-webkit-scrollbar-thumb:hover {
  background: #acacac;
} */

main .css-yk16xz-control{
  border: none;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  height: 50px;
}

main label.wrong .select .css-yk16xz-control {
  border-color: red;
}

main .select.multi .css-yk16xz-control, main .select.multi .css-1pahdxg-control{
  position: absolute;
  width: 100%;
  top: 0;
}

main .css-2b097c-container{
  height: 50px;
}

main .css-1hwfws3{
  padding: 0 16px;
  height: 48px;
  position: static;
}


main#offers .data .right>div.debt label:nth-child(2){
    width: 100%;
    position: relative;
}

main#offers .data .right>div.debt label:nth-child(2) input{
    width: 47%;
}

main#offers .data .right>div.debt label:nth-child(2) span{
    display: inline-block;
    background-image: url('../img/i.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    margin-left: 6px;
    cursor: pointer;
}

main#offers .data .right>div.debt label:nth-child(2) .i{
    position: absolute;
    width: 150px;
    padding: 8px 10px;
    font-size: 10px;
    line-height: 14px;
    background-color: white;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    left: 135px;
    top: -38px;
    visibility: hidden;
}

main#offers .data .right>div.debt label:nth-child(2) span:hover ~ .i{
    visibility: visible;
}

main#offers .data .right>div.personal{
    margin-top: 35px;
}

main#offers .data .right>div button{
    padding-left: 40px;
    padding-right: 40px;
    height: 53px;
    margin-top: 24px;
}

main#offers .data .right>div>p{
    font-size: 11px;
    font-weight: 100;
    line-height: 14px;
    width: 57%;
    margin: 24px 0 0;
}

main#offers .data .right>div>p a{
    text-decoration: underline;
    text-decoration-color: #a2a3a6;
}

main#offers .results{
    padding-top: 145px;
}

main#offers .results .greeding{
    color: var(--main-hover-color);
    font-size: 23px;
    line-height: 34px;
}

main#offers .results>p{
    font-size: 14px;
    line-height: 22px;
    width: 42%;
    margin: 30px 0 0;
}

main#offers .results .container{
    margin-top: 80px;
}

main#offers .results .container .row{
    width: 100%;
    box-shadow: 2px 7px 36px 2px #f3f3f3;
    margin-bottom: 65px;
}

main#offers .results .container .row .info{
    font-size: 17px;
    font-weight: 700;
    line-height: 22px;
    background-color: #f4f6fb;
    padding: 15px 45px;
}

main#offers .results .container .row .tr{
    display: flex;
    justify-content: space-between;
    padding: 35px 45px;
}

main#offers .results .container .row .tr .td{
    width: 16%;
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
}

main#offers .results .container .row .tr .td.logo{
    width: 11%;
    margin-right: 2%;
    margin-top: 4px;
}

main#offers .results .container .row .tr .td.logo img{
    max-width: 100%;
}

main#offers .results .container .row .tr .td .value{
    font-size: 22px;
    margin-bottom: 8px;
    width: 100%;
}

main#offers .results .container .row .tr .td .desc{
    font-size: 14px;
    line-height: 21px;
}

main#offers .results .container .row .tr .td button{
    padding: 15px 0;
    margin-left: auto;
    width: 100%;
}

main#offers .results .container .row .tr .td button.matched{
    background-color: white;
    color: #ebebeb;
    border: 1px solid #ebebeb;
}

main#offers .results .container .row .tr .td:nth-last-child(2), main#offers .results .container .row .tr .td:nth-last-child(3){
    width: 14%;
}

main#offers .results .container .row .tr .td:nth-child(2), main#offers .results .container .row .tr .td:nth-child(3){
    width: 17%;
}

main#offers .results .container .row .tr .td span{
    display: inline-block;
    background-image: url('../img/i.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 13px;
    height: 13px;
    margin-left: 3px;
    cursor: pointer;
}

main#unsubscribe .msg{
    text-align: center;
    font-size: 30px;
    padding-top: 100px;
    margin-bottom: 80px;
}

.privacy_policy--compliance p {
    max-width: 100% !important;
}